import { jsx } from "theme-ui"
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../ui-components/layout";

import AlternatingImage from "../../components/alternating-image/alternating-image";
import FullScreenImage from '../../components/full-screen-image/full-screen-image';
import SEO from "../../components/seo/seo";
import Text from "../../components/text/text";
import Prefooter from "../../components/prefooter/prefooter";

interface WorkProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
        githubLink: string;
      };
    };
    contentfulWork: {
      id: number;
      metaTitle: string;
      metaDescription: {
        metaDescription: string;
      };
      tags: [];
      slug: string;
      socialMediaImage: {
        file: {
          url: string;
        };
      };
      language: [];
      section: [];
    };
  };
}

export const workQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        name
        tagline
        githubLink
      }
    }
    contentfulWork(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription {
        metaDescription
      }
      socialMediaImage {
        file {
          url
        }
      }
      tags
      language
      slug
      section {
        ...AlternatingImageSection
        ...FullScreenImageSection
        ...TextSection
        ...Prefooter
      }
    }
  }
`;

// TODO: add back in when they want it ... FormSection

export default class Work extends React.Component<WorkProps, {}> {
  public render() {
    const { data } = this.props as WorkProps;
    const {
      section,
      metaTitle,
      metaDescription,
      socialMediaImage,
      slug,
      tags,
      language
    } = data.contentfulWork;

    const components = {
      // ContentfulFormSection: FormSection,
      ContentfulFullScreenImageSection: FullScreenImage,
      ContentfulAlternatingImageSection: AlternatingImage,
      ContentfulTextSection: Text,
      ContentfulPrefooter: Prefooter
    };

    // @ts-ignore
    const ComponentMatch = data => {
      // @ts-ignore
      const MatchingComponent = components[data.internal.type];
      return <MatchingComponent data={data} />;
    };

    return (
      <Layout>
        <SEO
          title={metaTitle}
          description={metaDescription.metaDescription}
          image={socialMediaImage.file.url}
          pathname={slug}
          lang={language}
        />
        <main>
          {section.map((component: any) => (
            <ComponentMatch
              {...component}
              key={component.heading || component.title}
            />
          ))}
        </main>
      </Layout>
    );
  }
}
